<template>
  <div class="h-11">
    <splide :options="splideOptions" :extensions="{ AutoScroll }">
      <splide-slide v-for="(company, index) in companies" :key="index">
        <div v-if="company.href">
          <a :href="company.href" target="_blank">
            <nuxt-img :src="company.src" :alt="company.alt" width="245px" height="98px" />
          </a>
        </div>
        <div v-else>
          <nuxt-img :src="company.src" :alt="company.alt" width="245px" height="98px" />
        </div>
      </splide-slide>
    </splide>
  </div>
</template>

<script setup>
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { companies, splideOptions } from "~/utils/carousel";
</script>
