export const splideOptions = {
  perPage: 5,
  type: "loop",
  gap: "1rem",
  height: "5rem",
  arrows: false,
  breakpoints: {
    540: {
      perPage: 2,
      speed: 1,
      gap: ".2rem",
      height: "4rem",
    },
  },
  autoScroll: {
    speed: 0.5,
    autoStart: true,
  },
  pagination: false,
  heightRatio: 0.5625,
  interval: 5000,
};

export const companies = [
  {
    src: "/images/carousel/bliss-health.svg",
    alt: "Bliss Health",
    href: "https://www.blisshealth.care/",
  },
  {
    src: "/images/carousel/mate.svg",
    alt: "Mate",
    href: "https://matefertility.com",
  },
  {
    src: "/images/carousel/local-infusion.svg",
    alt: "Local Infusion",
    href: "https://mylocalinfusion.com/",
  },
  {
    src: "/images/carousel/simplehealth.svg",
    alt: "SimpleHealth",
  },
  {
    src: "/images/carousel/copper.svg",
    alt: "Copper Union Health Associates",
  },
  {
    src: "/images/carousel/southshore-medical.svg",
    alt: "Southshore Medical",
  },
  {
    src: "/images/carousel/docker-mt-recovery.svg",
    alt: "Docker Mountain Recovery Center",
  },
  {
    src: "/images/carousel/spectrum-radiology.svg",
    alt: "Spectrum Radiology",
  },
];
